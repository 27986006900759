import { useCallback, useEffect, useMemo, useState } from 'react';

import { Accordion, ActionIcon, Card, Flex, Loader, Text } from '@mantine/core';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { useParams } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ForgeViewerDocument } from 'components/Autodesk/ForgeViewerDocument';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { compactRowHeight } from 'helpers/ag-grid/baseColumnDef';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { withHttp } from 'helpers/common';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import useSetupModule from 'hooks/useSetupModule';
import { useUsersInfo } from 'hooks/useUsersInfo';
import type { Task } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';
import { DrawingsList } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/DrawingsPopover';
import {
  AttachmentSectionName,
  AttachmentsList,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/AttachmentsList';
import type {
  Document,
  DocumentId,
  DocumentSkeleton,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';

import { getDaysRemainingWorkCellTask } from '../../WorkCellQueue/WorkCellQueuePage/common';
import type { TaskStatusType, WorkCellTask } from '../../WorkCellQueue/WorkCellQueuePage/types';
import { useTasks } from '../../WorkCellQueue/WorkCellQueuePage/useTasks';
import { getEventsColDef } from './columnDefs';
import { TaskControls } from './TaskControls';
import { convertSecondsToTimestamp, convertTimestampToSeconds } from './utils';

type Props = {
  task: Task;
  onTaskUpdated: (task: Task) => void;
  taskStatuses: TaskStatusType[];
  workCellTask: WorkCellTask | null | undefined;
};

const TaskProperty = ({ label, value }: { label: string; value: string | number }) => (
  <Flex justify="space-between" fz="sm" gap="sm">
    <Text c="dimmed">{label}</Text>
    <Text lineClamp={1} title={`${value}`}>
      {value}
    </Text>
  </Flex>
);

const baseGridProps: AgGridReactProps = {
  domLayout: 'autoHeight',
  headerHeight: compactRowHeight,
  rowHeight: compactRowHeight,
  defaultColDef: {
    resizable: false,
    suppressMovable: true,
    sortable: false,
    contextMenuItems: [],
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  },
};

const TaskViewerPageWrapped = (props: Props) => {
  const { getUsersData } = useUsersInfo();
  const { task, workCellTask, taskStatuses } = props;
  const [totalDrawings, setTotalDrawings] = useState<number>();
  const [totalAttachments, setTotalAttachments] = useState<number>();
  const { attachmentToDocumentMap } = useDocumentsCache();
  const [viewingDocument, setViewingDocument] = useState<Document | null>();
  const eventsColDef = useMemo(() => getEventsColDef(taskStatuses), [taskStatuses]);

  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedSection, setSelectedSection] = useState<'Drawing' | AttachmentSectionName>('Drawing');
  const [selectedDocumentId, setSelectedDocumentId] = useState<DocumentId | undefined | null>();
  const [viewingUrl, setViewingUrl] = useState<string | null>();

  // Responds to changes in selectedRow
  const onAttachmentRealized = useCallback(
    (documentSkeleton: DocumentSkeleton, rowNum: number, section: typeof selectedSection) => {
      setSelectedDocumentId(documentSkeleton.documentId);
      setViewingUrl(documentSkeleton.externalUrl);
      setSelectedSection(section);
      setSelectedRow(rowNum);
    },
    [],
  );
  // Called when the user actually clicks on a row
  const onAttachmentSelected = useCallback((document: Document, rowNum: number, section: typeof selectedSection) => {
    setViewingDocument(document);
    setSelectedDocumentId(document.documentId);
    setViewingUrl(null);
    setSelectedSection(section);
    setSelectedRow(rowNum);
  }, []);

  // When selectedDocumentId changes, look up the actual document
  // so the ForgeViewer can display it
  useEffect(() => {
    if (isNotNil(selectedDocumentId) && selectedDocumentId in attachmentToDocumentMap) {
      if (viewingDocument?.documentId !== attachmentToDocumentMap[selectedDocumentId]?.documentId) {
        setViewingDocument(attachmentToDocumentMap[selectedDocumentId]);
      }
    } else {
      setViewingDocument(null);
    }
  }, [attachmentToDocumentMap, selectedDocumentId, viewingDocument]);

  const events = useMemo(
    () =>
      task.time.events.filter(
        (e) =>
          taskStatuses.find((s) => s.taskStatusTypeName === 'Not Started')?.taskStatusTypeId !== e.taskStatusTypeId,
      ),
    [task.time.events, taskStatuses],
  );

  useEffect(() => {
    getUsersData(events.map((e) => e.userId));
  }, [events, getUsersData]);

  return (
    <>
      <BasePageHeader
        title={task.taskName}
        parentPage={{
          title: 'Work Cell Queue',
          url: '/shop/work-cell-queue',
        }}
        topLeftComponent={
          isNotNil(workCellTask) ? (
            <>
              <CellStatusBadge
                status={task.taskStatusTypeName}
                daysRemaining={getDaysRemainingWorkCellTask(workCellTask)}
              />
              {!task.isEnabled && (
                <AgGridStyleTooltip
                  openDelay={0}
                  label="This task has an incomplete predecessor."
                  style={{ maxWidth: 280 }}
                  withArrow
                >
                  <EvolveIcon color="gray.6" icon="Blocked" />
                </AgGridStyleTooltip>
              )}
              <Text>{workCellTask.workRequestItemName}</Text>
              <Text c="dimmed">Unit qty: {task.quantity}</Text>
            </>
          ) : null
        }
      />
      <Flex gap="xs" style={{ height: '100%', overflow: 'hidden' }}>
        <Card
          withBorder
          p={0}
          display="flex"
          style={{
            width: '30%',
            minWidth: 400,
            maxWidth: 500,
            height: '100%',
            overflow: 'hidden',
            flexDirection: 'column',
          }}
        >
          <Card style={{ flex: '0 0 auto' }}>
            {task.taskStatusTypeName !== 'Completed' ? (
              <TaskControls disabled={isNil(workCellTask)} {...props} />
            ) : (
              <Card withBorder radius="md">
                <Flex direction="column" gap="xs">
                  <TaskProperty label="Total time" value={task.time.totalTime} />
                  <TaskProperty label="Unit quantity" value={task.quantity} />
                  <TaskProperty
                    label="Unit time"
                    value={convertSecondsToTimestamp(convertTimestampToSeconds(task.time.totalTime) / task.quantity)}
                  />
                </Flex>
              </Card>
            )}
          </Card>
          <Accordion
            multiple
            defaultValue={['production-time']}
            style={{
              flex: '1 1 auto',
              overflowY: 'auto',
            }}
          >
            <Accordion.Item value="production-time">
              <Accordion.Control>
                <Text>Production time</Text>
              </Accordion.Control>
              <Accordion.Panel className="ag-theme-quartz small-table">
                <AgGridReact<Task['time']['events'][number]>
                  rowData={events}
                  columnDefs={eventsColDef}
                  {...baseGridProps}
                  noRowsOverlayComponent={() => <NoRowsOverlay label="Task has not been started." align="flex-start" />}
                />
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="mapped-drawings">
              <Accordion.Control>
                <Text>
                  Mapped drawings
                  {isNotNil(totalDrawings) && (
                    <Text c="dimmed" span ml="xs">
                      {totalDrawings}
                    </Text>
                  )}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Card withBorder radius="md">
                  <DrawingsList
                    taskId={task.taskId}
                    onDocumentSelected={onAttachmentSelected}
                    onDocumentCountLoaded={setTotalDrawings}
                    selectedRow={selectedSection === 'Drawing' ? selectedRow : undefined}
                    onRowRealized={onAttachmentRealized}
                  />
                </Card>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="attachments">
              <Accordion.Control disabled={isNil(workCellTask)}>
                <Text>
                  Task/WO/WR attachments
                  {isNotNil(totalAttachments) && (
                    <Text c="dimmed" span ml="xs">
                      {totalAttachments}
                    </Text>
                  )}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                {isNotNil(workCellTask) && (
                  <AttachmentsList
                    onDocumentCountLoaded={setTotalAttachments}
                    selectedRow={selectedSection !== 'Drawing' ? selectedRow : undefined}
                    selectedSection={selectedSection !== 'Drawing' ? selectedSection : undefined}
                    onRowRealized={onAttachmentRealized}
                    onDocumentSelected={onAttachmentSelected}
                    {...(task.taskStatusTypeName === 'Completed' || !task.isEnabled
                      ? {
                          readOnly: true,
                          workRequestId: workCellTask.workRequestId,
                          workOrderId: workCellTask.workOrderId,
                          taskId: workCellTask.taskId,
                        }
                      : {
                          readOnly: false,
                          workCellTask,
                        })}
                  />
                )}
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="task-properties">
              <Accordion.Control disabled={isNil(workCellTask)}>
                <Text>Task properties</Text>
              </Accordion.Control>
              <Accordion.Panel>
                {isNotNil(workCellTask) && (
                  <Card withBorder radius="md">
                    <Flex direction="column" gap="xs">
                      <TaskProperty label="Project Name" value={workCellTask.projectName} />
                      <TaskProperty label="Work Request Id" value={workCellTask.workRequestIdentifier} />
                      <TaskProperty label="Work Order Id" value={workCellTask.workOrderName} />
                      <TaskProperty label="Work Order Name" value={workCellTask.workRequestName} />
                      <TaskProperty label="Item" value={workCellTask.workRequestItemName} />
                      <TaskProperty label="Task Name" value={workCellTask.taskName} />
                      <TaskProperty label="Work Cell" value={workCellTask.workCellName} />
                    </Flex>
                  </Card>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Card>
        <Card withBorder style={{ width: '100%' }} p={0}>
          <ForgeViewerDocument
            headerContents={
              <Flex gap="md">
                <AgGridStyleTooltip label={`Previous ${selectedSection} Attachment`} withArrow>
                  <ActionIcon
                    size="sm"
                    onClick={() => {
                      setSelectedRow((r) => r - 1);
                      setViewingDocument(null);
                    }}
                  >
                    <EvolveIcon icon="ArrowLeft" color="inherit" size="xs" />
                  </ActionIcon>
                </AgGridStyleTooltip>
                <AgGridStyleTooltip label={`Next ${selectedSection} Attachment`} withArrow>
                  <ActionIcon
                    size="sm"
                    onClick={() => {
                      setSelectedRow((r) => r + 1);
                      setViewingDocument(null);
                    }}
                  >
                    <EvolveIcon icon="ArrowRight" color="inherit" size="xs" />
                  </ActionIcon>
                </AgGridStyleTooltip>
              </Flex>
            }
            document={viewingDocument}
            noDocumentSelected={
              viewingUrl ? (
                <>
                  <Text>External URL selected, click to follow.</Text>
                  <a href={withHttp(viewingUrl)} target="_blank" rel="noreferrer">
                    {viewingUrl}
                  </a>
                </>
              ) : (
                <>{isNil(selectedDocumentId) ? 'No drawings are mapped to this task.' : <Loader />}</>
              )
            }
          />
        </Card>
      </Flex>
    </>
  );
};

/**
 * Used to verify the selected `Task` both exists and is loaded into memory.
 */
export const TaskViewerPage = () => {
  const navigate = useEvolveNavigate('', true);
  const { currentModule } = useSetupModule();
  const module = useMemo(() => (currentModule() ?? 'shop').toLocaleLowerCase(), [currentModule]);

  const { id: taskId } = useParams();
  const { taskStatusTypes } = useTasks();
  const { data: taskFromNetwork, loading: loadingTask } = useWrappedGet<Task>(`shop/task/${taskId}`);
  const {
    data: [workCellTask],
    loading: loadingWorkCellTask,
  } = useWrappedPaginatedGet<WorkCellTask>(`shop/workCellTask`, {
    defaultConfig: {
      params: {
        taskId,
      },
    },
  });
  const [task, setTask] = useState<Task>();
  useEffect(() => {
    setTask(taskFromNetwork);
  }, [taskFromNetwork]);
  if (loadingTask || loadingWorkCellTask || isNil(taskStatusTypes)) {
    return <Loader m="lg" />;
  }
  if (isNil(task)) {
    setTimeout(() => navigate(`/${module}/work-cell-queue`));
    return null;
  }
  return (
    <TaskViewerPageWrapped
      task={task}
      onTaskUpdated={setTask}
      workCellTask={workCellTask}
      taskStatuses={taskStatusTypes}
    />
  );
};
