/* eslint-disable no-console */
// import * as Autodesk from "@types/forge-viewer";
import React from 'react';

import AutodeskEventBus from 'scripts/AutodeskEventBus';

import { onLoadPageNavExtension } from './autodesk-helper';

import './extensions/customToolbar';

const { Autodesk } = window;

const runtime = {
  options: null,
  ready: null,
};

function initializeViewerRuntime(
  accessToken,
  getAccessToken,
  env = 'AutodeskProduction2',
  api = 'streamingV2',
  shouldInitializeAuth = true,
) {
  runtime.options = { accessToken, getAccessToken, env, api, shouldInitializeAuth };
  runtime.ready = new Promise((resolve) => Autodesk.Viewing.Initializer(runtime.options, resolve));

  return runtime.ready;
}

class ForgeViewer extends React.Component {
  constructor(props) {
    super(props);
    /** @type {HTMLDivElement} */
    this.containerRef = null;
    /** @type {Autodesk.Viewing.GuiViewer3D} */
    this.viewer = null;
    this.viewerPageNumberRef = React.createRef();
    this.viewerTotalPageNumbersRef = React.createRef();
  }

  componentDidMount() {
    const { accessToken, getAccessToken, env, api, extensions, shouldInitializeAuth } = this.props;
    initializeViewerRuntime(accessToken, getAccessToken, env, api, shouldInitializeAuth)
      .then(() => {
        this.viewer = new Autodesk.Viewing.GuiViewer3D(this.containerRef, {
          extensions: extensions || [],
        });
        this.viewer.start();
        this.viewerPageNumberRef.current = 1;
        this.syncState({});
        this.viewer.loadExtension('CustomToolbarExtension');
      })
      .catch(console.error);

    AutodeskEventBus.on('page_nav', this.navigatePage);
  }

  componentDidUpdate(prevProps) {
    if (this.viewer) this.syncState(prevProps);
  }

  componentWillUnmount() {
    if (this.viewer) {
      this.viewer.finish();
      this.viewer = null;
    }

    AutodeskEventBus.remove('page_nav');
  }

  navigatePage = ({ direction }) => {
    const { setCurrentPage } = this.props;
    let nextPageNumber = this.viewerPageNumberRef.current;
    if (direction === 'prev') {
      nextPageNumber = nextPageNumber === 1 ? 1 : this.viewerPageNumberRef.current - 1;
    }

    if (direction === 'next') {
      nextPageNumber =
        nextPageNumber === this.viewerTotalPageNumbersRef.current
          ? this.viewerTotalPageNumbersRef.current
          : this.viewerPageNumberRef.current + 1;
    }

    this.syncState({}, nextPageNumber);
    this.viewerPageNumberRef.current = nextPageNumber;
    setCurrentPage(nextPageNumber);
  };

  gotoPage = (page) => {
    const { setCurrentPage } = this.props;
    this.syncState({}, page);
    this.viewerPageNumberRef.current = page;
    setCurrentPage(page);
  };

  syncState(prevProps, page = 1) {
    const { urn, setViewerRenderComplete } = this.props;
    if (!urn || urn === prevProps.urn) return;

    // load pdf files
    if (urn.startsWith('http')) {
      const currentModel = this.viewer?.impl?.model;
      if (currentModel) {
        this.viewer.impl.unloadModel(currentModel);
      }

      this.viewer
        ?.loadModel(urn, { page }, (model) => {
          this.viewerTotalPageNumbersRef.current = model?.loader?.pdf?.numPages ?? 0;

          this.viewer?.loadExtension('PageNavExtension').then(
            onLoadPageNavExtension({
              currentPageNumber: page,
              totalPageNumber: this.viewerTotalPageNumbersRef.current,
              gotoPageCb: this.gotoPage,
            }),
          );
        })
        .then(() => {
          setViewerRenderComplete?.(true);
        });
      return;
    }

    // load translated files
    Autodesk.Viewing.Document.load(
      `urn:${urn}`,
      (doc) => this.viewer?.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry()),
      console.error,
    );
  }

  render() {
    return <div ref={(ref) => (this.containerRef = ref)} />;
  }
}

export default ForgeViewer;
