import type React from 'react';

import { Flex, FlexProps } from '@mantine/core';
import type { AgGridReact } from 'ag-grid-react';

import { EvolveIcon, EvolveIconName } from 'assets/icons/EvolveIcon';
import { isNotNil } from 'helpers/isNotNil';

type Props = {
  label: React.ReactNode;
  icon?: EvolveIconName;
  gridRef?: React.RefObject<AgGridReact>;
} & FlexProps;

export const NoRowsOverlay = ({ gridRef, label, icon, ...props }: Props) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    gap="xs"
    px="md"
    fz={isNotNil(icon) ? 'xl' : undefined}
    c="gray"
    style={{
      lineHeight: 1.5,
      width: '100%',
      ...props.style,
    }}
    {...props}
  >
    {isNotNil(icon) && <EvolveIcon size="xl" icon={icon} color="inherit" />}
    <Flex direction="column" align="center" justify="center">
      {Object.keys(gridRef?.current?.api?.getFilterModel() ?? {}).length > 0
        ? 'No results found matching the current filters.'
        : label}
    </Flex>
  </Flex>
);
