import { Avatar } from '@mantine/core';

import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { useUsersInfo } from 'hooks/useUsersInfo';
import { UserId } from 'types/types-api';

type Props = { userId: UserId };

export const UserAvatar = ({ userId }: Props) => {
  const { cachedUserData } = useUsersInfo();
  return (
    <AgGridStyleTooltip label={cachedUserData[userId]?.completeName} withArrow openDelay={0}>
      <Avatar radius="100%" size="sm" />
    </AgGridStyleTooltip>
  );
};
