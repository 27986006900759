import { Task } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

export const convertTimestampToSeconds = (timestamp: Task['time']['totalTime']) => {
  const [currentHours, currentMinutes, currentSeconds] = timestamp.split(':').map((v) => parseInt(v, 10));
  return currentSeconds + currentMinutes * 60 + currentHours * 60 * 60;
};

const formatTime = (n: number) => (n >= 10 ? n : `0${n}`);
export const convertSecondsToTimestamp = (seconds: number) => {
  let currentSeconds = Math.ceil(seconds);
  let currentMinutes = 0;
  let currentHours = 0;
  currentMinutes += Math.floor(currentSeconds / 60);
  currentSeconds %= 60;
  currentHours += Math.floor(currentMinutes / 60);
  currentMinutes %= 60;
  return [currentHours, currentMinutes, currentSeconds].map(formatTime).join(':');
};
