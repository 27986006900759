/* eslint-disable class-methods-use-this */
import './multiSelectExtension';

const { Autodesk } = window;

const ICON = {
  MULTI: 'MULTI',
  SINGLE: 'SINGLE',
  WINDOW: 'WINDOW',
};

const ICON_CLASS = {
  POINTER: 'fa-mouse-pointer',
  GROUP: 'fa-object-ungroup',
  WINDOW: 'fa-window-maximize',
};

const FAS = 'fas';

class CustomToolbarExtension extends Autodesk.Viewing.Extension {
  constructor(viewer) {
    super(viewer);
    this.selectedOption = null;
  }

  load() {
    if (this.viewer.toolbar) {
      // Toolbar is already available, create the UI
      this.createUI();
    } else {
      // Toolbar hasn't been created yet, wait until we get notification of its creation
      this.onToolbarCreatedBinded = this.onToolbarCreated.bind(this);
      this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
    }

    this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.handleSelectionChanged);

    return true;
  }

  onToolbarCreated() {
    this.viewer.removeEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
    this.onToolbarCreatedBinded = null;
    this.createUI();
  }

  createUI() {
    const toolbarGroup = this.viewer.toolbar.getControl('navTools');

    if (toolbarGroup) {
      const dropdownMenu = new Autodesk.Viewing.UI.ComboButton('SelectCustomAssemblies');
      dropdownMenu.addClass('selectCustomAssemblies');
      dropdownMenu.setToolTip('Select');

      // Add the buttons to the drop-down menu
      dropdownMenu.addControl(this.createSingleSelectButton());
      dropdownMenu.addControl(this.createMultiSelectButton());
      dropdownMenu.addControl(this.createWindowSelectButton());
      dropdownMenu.icon.classList.add(FAS, ICON_CLASS.POINTER);

      toolbarGroup.addControl(dropdownMenu);
    }
  }

  handleSelectionChanged = ({ dbIdArray, model }) => {
    if (dbIdArray.length > 0) {
      const selectedDbIds = dbIdArray;
      model
        .getPropertyDb()
        .executeUserFunction(this.userFunction, selectedDbIds)
        .then((externalIds) => {
          // eslint-disable-next-line no-console
          console.log(`external ids are: ${externalIds.join(',')}`);
        });
    }
  };

  userFunction(pdb, userData) {
    const externalIds = userData.map((dbId) => pdb.getIdAt(dbId));
    return externalIds;
  }

  createSingleSelectButton() {
    const button = new Autodesk.Viewing.UI.Button('SingleSelectButton');
    button.onClick = (event) => {
      this.handleSingleSelectButtonClick(event);
      button.isActive = !button.isActive;
    };
    button.icon.classList.add(FAS, ICON_CLASS.POINTER);
    button.setToolTip('Single Select');
    button.addClass('customAssemblyButton');

    return button;
  }

  createMultiSelectButton() {
    const button = new Autodesk.Viewing.UI.Button('MultiSelectButton');
    button.onClick = (event) => this.handleMultiSelectButtonClick(event);
    button.icon.classList.add(FAS, ICON_CLASS.GROUP);
    button.setToolTip('Multi Select');

    return button;
  }

  createWindowSelectButton() {
    const button = new Autodesk.Viewing.UI.Button('WindowSelectButton');
    button.onClick = (event) => this.handleWindowButtonClick(event);
    button.icon.classList.add(FAS, ICON_CLASS.WINDOW);

    button.setToolTip('Window Select');

    return button;
  }

  handleSingleSelectButtonClick() {
    this.viewer.setActiveNavigationTool('pan');
    this.selectedOption = ICON.SINGLE;

    this.viewer.loadExtension('MySelectionWindow').then((ext) => {
      ext.unload();
    });

    this.updateDropdownMenuIcon();
  }

  handleMultiSelectButtonClick() {
    this.viewer.setActiveNavigationTool('pan');
    this.selectedOption = ICON.MULTI;

    this.viewer.loadExtension('MySelectionWindow').then((ext) => {
      ext.unload();
    });

    this.updateDropdownMenuIcon();
  }

  handleWindowButtonClick() {
    this.selectedOption = ICON.WINDOW;

    this.updateDropdownMenuIcon();

    this.viewer.loadExtension('MySelectionWindow').then((ext) => {
      ext.init();
      ext.load();
    });
  }

  updateDropdownMenuIcon() {
    const dropdownMenu = this.viewer.toolbar.getControl('navTools').getControl('SelectCustomAssemblies');
    if (dropdownMenu) {
      let iconClass;

      if (this.selectedOption === ICON.SINGLE) {
        iconClass = ICON_CLASS.POINTER;
      }

      if (this.selectedOption === ICON.MULTI) {
        iconClass = ICON_CLASS.GROUP;
      }

      if (this.selectedOption === ICON.WINDOW) {
        iconClass = ICON_CLASS.WINDOW;
      }

      dropdownMenu.icon.classList = '';
      dropdownMenu.icon.classList.add(FAS, iconClass);
    }
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension('CustomToolbarExtension', CustomToolbarExtension);

export default CustomToolbarExtension;
