import { useEffect, useState } from 'react';

import { ActionIcon, Card, Drawer, Flex, Loader, Popover, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ForgeViewerDocument } from 'components/Autodesk/ForgeViewerDocument';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import type { TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { useDocumentTypes } from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes/useDocumentTypes';

import type { Document, TaskDocument } from './SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import { useDocumentsCache } from './SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';

type Props = {
  taskId: TaskId;
};

/** Only these extension types are openable in Forge Viewer. */
const forgeViewerExtensions = ['.pdf'];

const DrawingDocument = ({
  taskDocument,
  onClick,
}: {
  taskDocument: TaskDocument;
  onClick: (document: Document) => void;
}) => {
  const { attachmentToDocumentMap } = useDocumentsCache();
  if (isNil(taskDocument.documentId)) return null;
  const document = attachmentToDocumentMap[taskDocument.documentId];
  const isLink = isNotNil(document) && forgeViewerExtensions.includes(document?.extension.toLocaleLowerCase());
  return (
    <Flex
      gap="xs"
      c={isLink ? 'primary' : undefined}
      className={isLink ? 'attachment-link' : ''}
      onClick={isNotNil(document) && isLink ? () => onClick(document) : undefined}
      align="center"
    >
      <EvolveIcon icon="DrawingDocument" color="inherit" />
      {isNotNil(document) ? <Text fz="sm">{document.documentName}</Text> : <Loader variant="dots" size="sm" />}
    </Flex>
  );
};

type DrawingsListProps = {
  onDocumentCountLoaded?: (documentCount: number) => void;
  setDocumentOpen?: (open: boolean) => void;
  selectedRow?: number;
  onRowRealized?: (taskDocument: TaskDocument, rowNum: number, section: 'Drawing') => void;
  onDocumentSelected?: (document: Document, rowNum: number, section: 'Drawing') => void;
};

export const DrawingsList = ({
  taskId,
  selectedRow,
  onRowRealized,
  onDocumentSelected,
  setDocumentOpen,
  onDocumentCountLoaded,
}: Props & DrawingsListProps) => {
  const [viewingDocument, setViewingDocument] = useState<Document | null>(null);
  useEffect(() => setDocumentOpen?.(isNotNil(viewingDocument)), [setDocumentOpen, viewingDocument]);
  const { drawingModelCNCIds } = useDocumentTypes();
  const { requestDocumentDetails } = useDocumentsCache();
  const {
    data: documents,
    fetchNextPage,
    loading,
    entireCount,
  } = useWrappedPaginatedGet<TaskDocument>('shop/taskDocument', {
    defaultConfig: {
      params: {
        taskId,
        documentTypeIds: drawingModelCNCIds,
        includeExternalUrl: false,
      },
    },
  });
  useEffect(() => {
    if (isNotNil(entireCount)) {
      onDocumentCountLoaded?.(entireCount);
    }
  }, [entireCount, onDocumentCountLoaded]);
  useEffect(() => {
    requestDocumentDetails(documents.map((d) => d.documentId).filter(isNotNil));
  }, [documents, requestDocumentDetails]);

  useEffect(() => {
    if (isNotNil(selectedRow) && isNotNil(onRowRealized)) {
      if (documents.length === 0) return;
      const rowCount = entireCount ?? 0;

      let rowIndex = selectedRow > documents.length - 1 ? 0 : selectedRow;
      // If rowIndex < 0, the user wants to loop to the *last* document
      if (rowIndex < 0) {
        // If we haven't loaded all the documents yet, we need to do that
        if (documents.length < rowCount) {
          fetchNextPage();
          return;
        }
        rowIndex = documents.length - 1;
      }
      onRowRealized?.(documents[rowIndex], rowIndex, 'Drawing');
      // If we're approaching the end of the current page, go ahead and load the next page
      if (rowIndex > rowCount - 5) {
        fetchNextPage();
      }
    }
  }, [documents, entireCount, fetchNextPage, onRowRealized, selectedRow]);

  return (
    <>
      <Flex direction="column" gap="xs" style={{ minWidth: 300 }}>
        {documents.map((d, i) => (
          <DrawingDocument
            key={d.taskDocumentId}
            taskDocument={d}
            onClick={onDocumentSelected ? (doc) => onDocumentSelected(doc, i, 'Drawing') : setViewingDocument}
          />
        ))}
        <TriggerOnVisible onVisible={fetchNextPage} loading={loading} loaderProps={{ m: 0, size: 'sm' }} />
      </Flex>
      {!loading && documents.length === 0 && (
        <Text c="dimmed" fz="sm">
          No drawings found.
        </Text>
      )}
      <Drawer
        title="Viewer"
        size="xl"
        opened={isNotNil(viewingDocument)}
        onClose={() => setViewingDocument(null)}
        styles={{ body: { height: 'calc(100% - 4rem)' } }}
        // Done temporarily to appear above the page Header bar
        // TODO: Remove once the header has a more reasonable zIndex
        zIndex={10000}
      >
        <Card p={0} withBorder style={{ height: '100%', width: '100%' }}>
          <ForgeViewerDocument document={viewingDocument} />
        </Card>
      </Drawer>
    </>
  );
};

export const DrawingsPopover = (props: Props) => {
  const [documentOpen, setDocumentOpen] = useState(false);
  return (
    <Popover withinPortal position="bottom-end" shadow="md" offset={-10} closeOnClickOutside={!documentOpen}>
      <Popover.Target>
        <ActionIcon variant="subtle" size="sm" color="primary">
          <EvolveIcon icon="DrawingDocument" color="inherit" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown style={{ maxHeight: 500, overflowY: 'auto' }}>
        <DrawingsList setDocumentOpen={setDocumentOpen} {...props} />
      </Popover.Dropdown>
    </Popover>
  );
};
