import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUser } from 'app/UserContext';
import { FILE_TYPE_FORMATS_DR } from 'constants/fileConstants';
import { FILE_FORMATS } from 'constants/globalConstants';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { Document } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';

const PDF_EXTENSIONS = ['.pdf'];

export const useGetGraphicType = (document?: Document | null) => {
  const { user } = useUser();
  const [typeFileFormat, setTypeFileFormat] = useState<typeof FILE_FORMATS[keyof typeof FILE_FORMATS] | null>(null);
  const { getPresignedUrlForDocument } = useDocumentsCache();
  const [signedURL, setSignedUrl] = useState<string>();

  useEffect(() => {
    if (document?.documentId && isNotNil(user)) {
      if (PDF_EXTENSIONS.includes(document.extension.toLowerCase())) {
        setTypeFileFormat(FILE_FORMATS.PDF);
        getPresignedUrlForDocument(document.documentId, {
          expirationHours: 24,
          requestedBy: user.userId,
          verb: 'GET',
        }).then((r) => setSignedUrl(r?.preSignedURL));
      }
    }
  }, [document, getPresignedUrlForDocument, user]);

  const dynamicProps = useMemo(() => {
    if (typeFileFormat === FILE_FORMATS.PDF)
      return {
        shouldInitializeAuth: false,
        env: 'Local',
        urn: signedURL,
        extensions: ['Autodesk.PDF'],
        accessToken: '',
        getAccessToken: () => null,
        useADP: false,
      };
    return null;
  }, [typeFileFormat, signedURL]);

  const downloadDocument = useCallback(() => {
    if (isNil(document) || isNil(user)) return;
    getPresignedUrlForDocument(document.documentId, {
      expirationHours: 24,
      requestedBy: user.userId,
      verb: 'GET',
    }).then((res) => {
      if (res?.preSignedURL) {
        window.open(res.preSignedURL, '_blank');
      }
    });
  }, [document, getPresignedUrlForDocument, user]);

  const isSupported = useMemo(
    () => FILE_TYPE_FORMATS_DR.some((formatFile) => `.${formatFile}` === document?.extension?.toLowerCase()),
    [document?.extension],
  );

  return { dynamicProps, isSupported, downloadDocument };
};

export default useGetGraphicType;
