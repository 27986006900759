import './forge-container.css';
import type { ReactNode } from 'react';

import { Box, Flex, Loader, Text } from '@mantine/core';

import { ForgeViewer } from 'components/Autodesk';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { isNotNil } from 'helpers/isNotNil';
import type { Document } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';

import { useGetGraphicType } from './hooks/useGetGraphicType';

export const ForgeViewerDocument = ({
  document,
  headerContents,
  noDocumentSelected = 'Select a drawing.',
  noHeader = false,
}: {
  document?: Document | null;
  headerContents?: ReactNode;
  noHeader?: boolean;
  noDocumentSelected?: ReactNode;
}) => {
  const { dynamicProps, isSupported, downloadDocument } = useGetGraphicType(document);
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {!noHeader && (
        <Flex px="sm" align="center" className="forge-header">
          {headerContents}
          <Text fw={500} ml="md">
            {document?.documentName}
          </Text>
        </Flex>
      )}
      {isNotNil(document) ? (
        <>
          {isSupported ? (
            <>
              {isNotNil(dynamicProps?.urn) ? (
                <Box pos="relative" className="forge-container">
                  <ForgeViewer key={document.documentId} {...dynamicProps} />
                </Box>
              ) : (
                <Flex align="center" justify="center" style={{ height: '100%' }}>
                  <Loader />
                </Flex>
              )}
            </>
          ) : (
            <Flex align="center" justify="center" style={{ height: '100%' }}>
              <NoRowsOverlay
                label={
                  <Flex ta="center" direction="column" gap={4}>
                    <Text>File type unsupported.</Text>
                    <Text>
                      Click{' '}
                      <Text fw={500} span className="attachment-link" onClick={downloadDocument}>
                        here
                      </Text>{' '}
                      to download.
                    </Text>
                  </Flex>
                }
                icon="DrawingDocument"
              />
            </Flex>
          )}
        </>
      ) : (
        <Flex align="center" justify="center" style={{ height: '100%' }}>
          <NoRowsOverlay label={noDocumentSelected} icon="DrawingDocument" />
        </Flex>
      )}
    </Box>
  );
};

export default ForgeViewerDocument;
