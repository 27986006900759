import { useCallback, useMemo, useState } from 'react';

import { MIME_TYPES } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';

import { CombinedUser, useUser } from 'app/UserContext';
import { extractErrorMessage } from 'helpers/extractError';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { ProjectId } from 'hooks/projectsAndFacilities/useProjects';
import type { TaskId, TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { WorkRequest, WorkRequestId } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import type { WorkOrder, WorkOrderId } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';
import type { CompanyId, UserId } from 'types/types-api';

import type { Document, DocumentId, DocumentTypeId, Folder } from './types';
import { useDocumentsCache } from './useDocumentsCache';

const getDocumentStoragePath = (storagePath: string, fileName: string) => {
  const documentGUID = uuidv4();
  return `${storagePath}/${documentGUID}-${fileName}`;
};

const DOCUMENT_TYPE_COMMON = '540f5ec2-6646-44f5-834c-a87d7369a253' as DocumentTypeId;

export const FILE_TYPE_ACCEPT = [
  MIME_TYPES.xls,
  MIME_TYPES.xlsx,
  MIME_TYPES.csv,
  MIME_TYPES.pdf,
  MIME_TYPES.jpeg,
  MIME_TYPES.png,
];

type UploadSource =
  | { workRequest: WorkRequest }
  | { workOrder: WorkOrder }
  | { user: CombinedUser }
  | { taskType: TaskType }
  | { workCellTask: WorkCellTask };
const getSourceId = (uploadSource: UploadSource) => {
  if ('workRequest' in uploadSource) {
    return uploadSource.workRequest.workRequestId;
  }
  if ('workOrder' in uploadSource) {
    return uploadSource.workOrder.workOrderId;
  }
  if ('taskType' in uploadSource) {
    return uploadSource.taskType.taskTypeId;
  }
  if ('workCellTask' in uploadSource) {
    return uploadSource.workCellTask.taskId;
  }
  return uploadSource.user.userId;
};
const getObjectUrl = (companyId: CompanyId, uploadSource: UploadSource) => {
  if ('taskType' in uploadSource) {
    return `all/company/${companyId}/taskType/${uploadSource.taskType.taskTypeId}/images`;
  }
  if ('user' in uploadSource) {
    return `all/company/${companyId}/users/${uploadSource.user.userId}/images`;
  }
  return `all/company/${companyId}/field/${getSourceId(uploadSource)}/attachments/${DOCUMENT_TYPE_COMMON}`;
};

export const useUploadDocuments = () => {
  const { user } = useUser();
  const { getPresignedUrl, addDocumentToCache } = useDocumentsCache();
  const { fetchPage: getExistingFolders } = useWrappedPaginatedGet<Folder>('docmgt/folder', { lazy: true });
  const { apiCall: createFolder } = useWrappedPost<
    Folder,
    {
      companyId: CompanyId;
      folderName: string;
      storagePath: string;
      folderDescription?: string;
      projectId?: ProjectId;
    }
  >('docmgt/folder');

  const { apiCall: addDocumentToFolder } = useWrappedPost<
    Document,
    {
      documentName: string;
      documentTypeId: DocumentTypeId;
      folderId: string;
      requestedBy: UserId;
      storagePath: string;
      extension: string;
    }
  >('docmgt/document');
  const { apiCall: addDocumentsToWorkRequest } = useWrappedPost<
    unknown,
    {
      workRequestId: WorkRequestId;
    } & (
      | {
          documentIds: DocumentId[];
        }
      | {
          externalUrl: string;
        }
    )
  >('shop/workRequestDocument');
  const { apiCall: addDocumentsToWorkOrder } = useWrappedPost<
    unknown,
    {
      workOrderId: WorkOrderId;
    } & (
      | {
          documentIds: DocumentId[];
        }
      | {
          externalUrl: string;
        }
    )
  >('shop/workOrderDocument');
  const { apiCall: addDocumentsToTask } = useWrappedPost<
    unknown,
    {
      taskId: TaskId;
    } & (
      | {
          documentIds: DocumentId[];
        }
      | {
          externalUrl: string;
        }
    )
  >('shop/taskDocument');

  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const uploadDocuments = useCallback(
    async (
      files: File[],
      attachParams: UploadSource & {
        project?: {
          projectName: string;
          projectId: ProjectId;
        };
      },
    ): Promise<DocumentId[]> => {
      if (isNil(user)) return [];
      setUploadingDocuments(true);
      const { companyId, userId: requestedBy } = user;

      const sourceId = getSourceId(attachParams);
      const folderPath = getObjectUrl(companyId, attachParams);

      const existingFolder: Folder | undefined = (
        await getExistingFolders(
          {
            skip: 0,
            take: 1,
            searchPhrase: sourceId,
          },
          { params: { companyId } },
        )
      ).data?.[0];

      const { project } = attachParams;
      const folder =
        existingFolder ??
        (await createFolder({
          companyId,
          storagePath: folderPath,
          folderName: sourceId,
          projectId: project?.projectId,
          folderDescription: project?.projectName,
        }));

      return Promise.all(
        files.map(async (file) => {
          const documentStoragePath = getDocumentStoragePath(folderPath, file.name);
          const { preSignedURL, objectKey, contentType } = await getPresignedUrl({
            expirationHours: 1,
            objectKey: documentStoragePath,
            requestedBy,
            verb: 'PUT',
          });
          try {
            const putRest = await fetch(preSignedURL, {
              method: 'PUT',
              mode: 'cors',
              body: file,
              headers: {
                'Content-Type': contentType,
              },
            });
            if (!putRest.ok) {
              throw await putRest.text();
            }
          } catch (e) {
            const extractedError = extractErrorMessage(e);
            notifications.show({
              title: 'Something went wrong',
              message: `${extractedError}`,
              color: 'red',
            });
            return null;
          }
          const extension = file.name.slice(file.name.lastIndexOf('.'));
          const document = await addDocumentToFolder({
            documentName: file.name,
            documentTypeId: DOCUMENT_TYPE_COMMON,
            folderId: folder.folderId,
            storagePath: objectKey,
            extension: extension.startsWith('.') && !extension.includes('/') ? extension : '',
            requestedBy,
          });
          addDocumentToCache(document);
          return document.documentId;
        }),
      )
        .then((d) => d.filter(isNotNil))
        .then(async (documentIds) => {
          if (documentIds.length > 0) {
            if ('workRequest' in attachParams) {
              await addDocumentsToWorkRequest({
                documentIds,
                workRequestId: attachParams.workRequest.workRequestId,
              });
            } else if ('workOrder' in attachParams) {
              await addDocumentsToWorkOrder({
                documentIds,
                workOrderId: attachParams.workOrder.workOrderId,
              });
            }
            if (!('user' in attachParams)) {
              notifications.show({
                title: 'Successfully uploaded',
                message: `Uploaded ${documentIds.length} attachments`,
                color: 'green',
              });
            }
          }
          return documentIds;
        })
        .finally(() => setUploadingDocuments(false));
    },
    [
      addDocumentToCache,
      addDocumentToFolder,
      addDocumentsToWorkOrder,
      addDocumentsToWorkRequest,
      createFolder,
      getExistingFolders,
      getPresignedUrl,
      user,
    ],
  );

  const uploadDocument = useCallback(
    async (
      file: File,
      attachParams: UploadSource & {
        project?: {
          projectName: string;
          projectId: ProjectId;
        };
      },
    ): Promise<DocumentId | undefined> => (await uploadDocuments([file], attachParams))[0] ?? undefined,
    [uploadDocuments],
  );

  const [addingLink, setAddingLink] = useState(false);
  const addLink = useCallback(
    async (
      externalUrl: string,
      attachParams:
        | { workOrder: WorkOrder }
        | { workRequest: WorkRequest }
        | {
            workCellTask: WorkCellTask;
          },
    ) => {
      try {
        setAddingLink(true);
        if ('workRequest' in attachParams) {
          await addDocumentsToWorkRequest({
            externalUrl,
            workRequestId: attachParams.workRequest.workRequestId,
          });
        } else if ('workOrder' in attachParams) {
          await addDocumentsToWorkOrder({
            externalUrl,
            workOrderId: attachParams.workOrder.workOrderId,
          });
        } else if ('workCellTask' in attachParams) {
          await addDocumentsToTask({
            externalUrl,
            taskId: attachParams.workCellTask.taskId,
          });
        }
        notifications.show({
          title: 'Successfully added',
          message: `Added ${externalUrl}`,
          color: 'green',
        });
      } finally {
        setAddingLink(false);
      }
    },
    [addDocumentsToTask, addDocumentsToWorkOrder, addDocumentsToWorkRequest],
  );

  return useMemo(
    () => ({ uploadDocument, uploadDocuments, addLink, saving: uploadingDocuments || addingLink }),
    [addLink, addingLink, uploadDocument, uploadDocuments, uploadingDocuments],
  );
};
